/* eslint-disable react-hooks/exhaustive-deps */
import { EventActions, OtherActions, UserActions } from "../../store/actions";

import React, { useEffect, useRef, useState } from "react";

import { useTranslation } from "react-i18next";
import { useWindowSize } from "../../hooks";
import { useFilterLinkConfig } from "../../hooks/";

import { Helmet } from "react-helmet";

import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import Jumbotron from "react-bootstrap/Jumbotron";

import EventFilterGroupContainer from "../../containers/EventFilterGroupContainer";

import { HeroBanner } from "@/components/HeroBanner";
import { ECCBanner } from "../../components/ECCBanner";
import { ProductGrid } from "../../components/ProductGrid";
import { PromoSection } from "../../components/PromoSection";
import { ThreeColComponent } from "../../components/ThreeColComponent";

import { ErrorPage } from "../ErrorPage";

import { ReactComponent as ExternalUrlIcon } from "../../assets/images/icon_external_url.svg";

import bgImg from "../../assets/images/ecc/ECC_Astro_Elements_Header.png";
import bgImg2x from "../../assets/images/ecc/ECC_Astro_Elements_Header@2x.png";
import bgImgMob from "../../assets/images/ecc/ECC_Landscape_Header_BG_Mobile.png";
import bgImgMob2x from "../../assets/images/ecc/ECC_Landscape_Header_BG_Mobile@2x.png";

import { ReactComponent as ShareIcon } from "../../assets/images/icon_share.svg";

import { IS_MOCK_TBID_USER } from "@/config/environment";
import { initiateLogin } from "@/utils/userHelper";

import TrendingTopicsContainer from "@/containers/TrendingTopicsContainer";
import "./ExpertCoaching.scss";

import Accordion from "react-bootstrap/Accordion";
import Button from "react-bootstrap/Button";
import ListGroup from "react-bootstrap/ListGroup";

import { headlessEngine } from "@/configureCoveo";
import { scrollToElem } from "@/utils/interfaceHelpers.js";
import { loadPaginationActions } from "@coveo/headless";

import { ECCFilterTabs } from "@/components/Coveo/ECCFilterTabs";
import { Facet, FacetDateRange, FacetToggle } from "@/components/Coveo/Facet";
import { Pager } from "@/components/Coveo/Pager";
import { QuerySummary } from "@/components/Coveo/QuerySummary";
import { ResultList } from "@/components/Coveo/ResultList";
import { SearchPills } from "@/components/Coveo/SearchPills";
import { bindUrlManager } from "@/components/Coveo/UrlManager";
import {
  breadcrumbs,
  categoryFacet,
  customerRoleFacet,
  dateFacet,
  deliveryRoleExpertiseFacet,
  deliveryTypeSubTypeFacet,
  eccAdminfundamentalsTab,
  eccCoachingTab,
  eccOverviewTab,
  eventTypeFacet,
  headlessResultTemplateManager,
  languageFacet,
  pager,
  productFacet,
  querySummary,
  regionFacet,
  resultList,
  searchParametersBreadcrumbs,
  searchParametersFacet,
  searchParametersFacetDateRange,
  subCategoryFacet,
  tierFacet,
} from "@/controllers/CoveoControllers";

import { SUCCESS_REVIEW_CASE_URL } from "../../config/environment";

const { loadOnDemandEvents } = EventActions;

const { getUserFavoritesAll } = UserActions;

const { toggleShareModal } = OtherActions;

const ExpertCoaching = ({
  filterGroups,
  criticalError,
  dispatch,
  user,
  tb_id,
  appConfig,
  onDemandEventsList,
  trendingTopicsList,
  eccType,
  userSearchToken,
  timezone,
  locale,
  language,
}) => {
  const eventsListRef = useRef();
  const { t } = useTranslation();
  const filterConfigParams = useFilterLinkConfig();

  const [windowWidth] = useWindowSize();
  const mobileBreak = 991;

  const [isExpanded, setIsExpanded] = useState(false);

  const [searchParametersState, setSearchParametersState] = useState(
    searchParametersFacet.state
  );

  const decoratedOnClick = () => {
    if (windowWidth <= mobileBreak) {
      setIsExpanded(!isExpanded);
    }
  };

  useEffect(() => {
    if (
      eccType !== "overview" &&
      eccType !== "expertcoaching" &&
      eccType !== "adminfundamentals" &&
      eccType !== "successreviews"
    ) {
      setNotFound(true);
    }

    // console.log("eccType: " + eccType);
    if (eccType === "expertcoaching") {
      initOnDemandEvents();
    }

    if (tb_id && !hasFavoritesInit) {
      dispatch(getUserFavoritesAll({ tb_id }));
      setHasFavoritesInit(true);
    }

    //Coveo
    const unsubscribeUrlManager = bindUrlManager(headlessEngine);
    const searchParametersSubcribe = searchParametersFacet.subscribe(() => {
      // manually update Coveo search to page type
      let params = searchParametersFacet.state;
      setSearchParametersState(params);
    });

    updateCoveoFilters();

    return () => {
      unsubscribeUrlManager();
      searchParametersSubcribe();
    };
  }, [userSearchToken, searchParametersFacet]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (languageFacet.state.canShowMoreValues) {
        languageFacet.showMoreValues();
      }
    }, 1500);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    updateCoveoFilters();
    return () => {
      languageFacet.deselectAll();
    };
  }, []);

  const updateCoveoFilters = () => {
    languageFacet.deselectAll();

    // check for a user and set language
    if (language || (user && user.tb_id)) {
      let preferredLanguage = language || user.preferred_language;
      // get all the facet values
      let languageFacetValueArr = languageFacet.state.values;
      // search for matching language
      let languageFacetSelect = languageFacetValueArr.map((value) => {
        // return true if so
        if (value.value === preferredLanguage) {
          return true;
        }
        // default false
        return false;
      });

      if (languageFacetSelect) {
        // select the value & set state
        languageFacet.toggleSelect({
          value: preferredLanguage,
          state: "selected",
        });
      }
    }
  };

  const [hasFavoritesInit, setHasFavoritesInit] = useState(false);
  const [notFound, setNotFound] = useState(false);

  const initOnDemandEvents = () => {
    dispatch(loadOnDemandEvents());
  };

  // when login is clicked
  const trailblazerLogin = async () => {
    initiateLogin(IS_MOCK_TBID_USER, "/");
  };

  const resetPage = () => {
    const { updatePage } = loadPaginationActions(headlessEngine);
    headlessEngine.dispatch(updatePage(1));
  };

  const seeAllEvents = (btnType) => {
    scrollToElem("filter-tabs-tab-" + btnType);
  };

  const showShareModal = () => {
    let modalPayload = { show: true };
    dispatch(toggleShareModal(modalPayload));
  };

  const pageTitle = (eccType === 'successreviews' ? t("Success Review") : t("Expert Coaching")) + " - " + t("Customer Success Events");

  if (criticalError) {
    return <ErrorPage errorType="500" />;
  }

  if (notFound) {
    return <ErrorPage errorType="404" />;
  }

  let eventTypeKey = "dashboard";
  let premierStatus = "Premier";
  let userPremierPromoKey = "premier";
  let userIsStandard = false;
  let userSuccessPlan = "standard";

  if (user && user.success_plan) {
    if (user.success_plan === "signature" || user.success_plan === "premier") {
      eventTypeKey = "dashboard-premier";
      premierStatus =
        user.success_plan === "signature" ? "Signature" : "Premier";
      userPremierPromoKey = user.success_plan;
      userSuccessPlan = "signature";
    } else if (user.success_plan === "standard") {
      userIsStandard = true;
    }
  }

  const renderStandard =
    user && user.success_plan && user.success_plan === "standard";

  const renderOverview = eccType === "overview";
  const renderExpertCoaching = eccType === "expertcoaching";
  const renderAdminfundamentals = eccType === "adminfundamentals";
  const renderSuccessReviews = eccType === "successreviews";

  let currentCoveoTab;
  let coveoListTitle;
  let coveoListDescription;
  switch (eccType) {
    case "overview":
      currentCoveoTab = eccOverviewTab;
      coveoListTitle = "Explore Success Plan Events";
      coveoListDescription = "Explore Success Plan Events Description";
      break;
    case "expertcoaching":
      currentCoveoTab = eccCoachingTab;
      coveoListTitle = "Explore Expert Coaching Topics";
      coveoListDescription = "Explore Success Plan Events Description";
      break;
    case "adminfundamentals":
      currentCoveoTab = eccAdminfundamentalsTab;
      coveoListTitle = "Explore Upcoming Admin Fundamentals";
      coveoListDescription = "Explore Upcoming Admin Fundamentals Description";
      break;
    default:
      currentCoveoTab = eccOverviewTab;
      break;
  }

  if (!renderSuccessReviews) {
    currentCoveoTab.select();
  }

  const RenderFastTrackThreeCol = () => {
    const data = [
      {
        title: "Expert Coaching",
        description: "ECC Expert Coaching Description",
        href: "/successplanevents/expertcoaching",
        cta: "ECC Get Started",
      },
      {
        href: "/successplanevents/adminfundamentals",
        title: "Admin Fundamentals",
        description: "ECC Admin Fundamentals Description",
        cta: "Learn More",
      },
      {
        href: "/successplanevents/successreviews",
        title: "Success Reviews",
        description: "ECC Success Reviews description",
        cta: "Learn More",
      }
    ];

    return (
      <ThreeColComponent title="Fast Track Your Success" childData={data} />
    );
  };

  const RenderWaysToLearnThreeCol = () => {
    const data = [
      {
        title: "Webinar Sessions",
        description: "ECC Webinar Sessions Description",
        href: `/search#tab=Coaching&f[successeventdeliverytypesubtype]=webinar&f[sitemaplanguage]=${language}`,
        cta: "Register for a Session",
      },
      {
        title: "Individual Sessions",
        description: "ECC Individual Sessions Description",
        href: `/search#tab=Coaching&f[successeventdeliverytypesubtype]=individual-session&f[sitemaplanguage]=${language}`,
        cta: "Request a Session",
      },
      {
        title: "Videos",
        description: "ECC Videos Description",
        href: `/search#tab=Coaching&f[successeventdeliverytypesubtype]=video&f[sitemaplanguage]=${language}`,
        cta: "Watch Video Sessions",
      },
    ];

    return (
      <ThreeColComponent
        title="Three Different Ways to Learn"
        childData={data}
        showBg={false}
      />
    );
  };

  const RenderComePreparedThreeCol = () => {
    const data = [
      {
        title: "Invite your team title",
        description: "Invite your team section"
      },
      {
        title: "Know your goals title",
        description: "Know your goals section"
      },
      {
        title: "Bring your questions title",
        description: "Bring your questions section"
      },
    ];

    return (
      <Container className="comePrepared">
            <h2 className="groupHeader">
                {t("Come Prepared title")}
            </h2>
            <Row className="threeCol-row">
                {data.map((obj, idx) => (
                    <Col className="threeCol-col">
                      <h3 className="groupHeader">{t(`${obj.title}`)}</h3>
                      <p className="description">{t(`${obj.description}`)}</p>
                    </Col>
                ))}
            </Row>
        </Container>
    );
  };

  const RenderECCBanner = () => {
    return (
      <div>
        <ECCBanner
          minHeight="379px"
          eventProductKey="default"
          eventTypeKey={eventTypeKey}
          user={user}
        >
          <Row>
            <Col xs={12} lg={6}>
              <Jumbotron>
                <h1 className="eccHeader">
                  {t(`Expert Coaching Title ${eccType} ${userSuccessPlan}`)}
                </h1>
                <p className="eccDescription">
                  {t(
                    `Expert Coaching Description ${eccType} ${userSuccessPlan}`
                  )}
                </p>
                {renderOverview && (
                  <Button
                    variant="primary"
                    onClick={() => seeAllEvents("overview")}
                  >
                    {t(`Explore All Topics ${eccType}`)}
                  </Button>
                )}
                {renderExpertCoaching && (
                  <Button
                    className="d-none d-lg-block"
                    variant="primary"
                    onClick={() => seeAllEvents("expert-coaching")}
                  >
                    {t(`Explore All Topics ${eccType}`)}
                  </Button>
                )}
                {renderExpertCoaching && (
                  <Button
                    className="mobButton"
                    variant="primary"
                    onClick={() => seeAllEvents("expert-coaching")}
                  >
                    {t(`Explore All Topics ${eccType}`)}
                  </Button>
                )}
              </Jumbotron>
            </Col>
            <Col xs={12} lg={6}>
              <img
                className="imageBanner"
                src={bgImg}
                srcSet={bgImg2x}
                alt={eccType}
              />
            </Col>
            <Col xs={12}>
              <img
                src={bgImgMob}
                srcSet={bgImgMob2x}
                alt={eccType}
                className="mobBanner"
              />
            </Col>
          </Row>
        </ECCBanner>
      </div>
    );
  };

  const RenderSuccessReviews = () => {
    let successReviewURL = SUCCESS_REVIEW_CASE_URL;
    const langMap = {'de': 'de', 'es': 'es-MX', 'fr': 'fr', 'jp': 'ja', 'pt': 'pt-BR'};
    if (!language || !(language in langMap)) {
      successReviewURL = successReviewURL.replace('{lang}/', '') + '?redirect=false';
    } else {
      successReviewURL = successReviewURL.replace('{lang}', langMap[language]);
    }
    return (
      <div>
        <PromoSection promoTypeKey="success-reviews" imageAlignment="last">
          <h3 className="groupHeader">{t("Success Reviews")}</h3>
          <p>{t("Success Reviews section")}</p>
          <a
            className="btn icon-button btn-primary"
            href={successReviewURL}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("Request a Success Review")}
          </a>
        </PromoSection>
        <Row />
        <div className="keyBenefits container">
          <h2 className="groupHeader">{t("Key Benefits title")}</h2>
          <p
            dangerouslySetInnerHTML={{
              __html: t(
                "Key Benefits section"
              ),
            }}
          ></p>
        </div>
        <Row />
        <RenderComePreparedThreeCol />
        <Row />
        <div className="requestSuccessReview container">
          <h2 className="groupHeader">{t("Request a Success Review")}</h2>
          <p
            dangerouslySetInnerHTML={{
              __html: t(
                "Request a Success Review section"
              ),
            }}
          ></p>
          <Row />
          <a
            className="btn icon-button btn-primary"
            href={successReviewURL}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("Request a Success Review")}
          </a>
        </div>
      </div>
    );
  };

  const RenderPremierFooterBanner = () => {
    return (
      <HeroBanner
        minHeight="379px"
        eventProductKey="default"
        eventTypeKey="footer-banner-premier"
        user={user}
        layoutType="footer"
      >
        <Row>
          <Col
            xs={{ span: 12, offset: 0 }}
            sm={{ span: 8, offset: 2 }}
            lg={{ span: 6, offset: 3 }}
          >
            <Jumbotron>
              <h3 className="groupHeader">
                {t("Premier footer promo Title", {
                  premier_status: premierStatus,
                })}
              </h3>
              <p>
                {t("Premier footer promo Description", {
                  premier_status: premierStatus,
                })}
              </p>
              <a
                className="btn btn-primary icon-button"
                href="https://cloud.mail.salesforce.com/success-center/success-plans/?utm_source=success_events&utm_medium=standard_home_footer"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("Premier footer promo Learn More CTA", {
                  premier_status: premierStatus,
                })}
                <ExternalUrlIcon />
              </a>
            </Jumbotron>
          </Col>
        </Row>
      </HeroBanner>
    );
  };

  const RenderPersonalizedGuidance = () => {
    const guidanceURL = `/search#tab=Coaching&f[successeventdeliverytypesubtype]=individual-session&f[sitemaplanguage]=${language}`;
    return (
      <PromoSection promoTypeKey="personalized-guidance" imageAlignment="first">
        <h3 className="groupHeader">{t("Get Personalized Guidance")}</h3>
        <p>{t("Personalized Guidance Description")}</p>
        <a
          className="btn icon-button btn-secondary bordered-btn"
          href={guidanceURL}
          target="_blank"
          rel="noopener noreferrer"
        >
          {t("Personalized Guidance CTA")}
        </a>
      </PromoSection>
    );
  };

  const RenderECProductGrid = () => {
    let filterURL = `/search#tab=Coaching&f[sitemaplanguage]=${language}&f[successeventproduct]=`;
    const data = [
      {
        title: "Commerce",
        href: `${filterURL}commerce`,
        icon: "commerce-icon",
      },
      {
        title: "CRM Analytics",
        href: `${filterURL}crm-analytics`,
        icon: "analytics-icon",
      },
      {
        title: "Data Cloud",
        href: `${filterURL}data-cloud`,
        icon: "datacloud-icon",
      },
      {
        title: "Experience",
        href: `${filterURL}experience`,
        icon: "experience-icon",
      },
      {
        title: "Heroku",
        href: `${filterURL}heroku`,
        icon: "heroku-icon",
      },
      {
        title: "Industries",
        href: `${filterURL}communications,consumer-goods,financial-services,healthcare-&-life-sciences,industries,manufacturing,public-sector`,
        icon: "industries-icon",
      },
      {
        title: "Marketing",
        href: `${filterURL}marketing,marketing-cloud-account-engagement,marketing-cloud-intelligence,marketing-cloud-personalization`,
        icon: "marketing-icon",
      },
      {
        title: "MuleSoft",
        href: `${filterURL}integration`,
        icon: "mulesoft-icon",
      },
      {
        title: "Net Zero",
        href: `${filterURL}net-zero`,
        icon: "netzero-icon",
      },
      {
        title: "Platform",
        href: `${filterURL}platform`,
        icon: "platform-icon",
      },
      {
        title: "Revenue",
        href: `${filterURL}revenue`,
        icon: "revenue-icon",
      },
      {
        title: "Sales",
        href: `${filterURL}sales`,
        icon: "sales-icon",
      },
      {
        title: "Salesforce for Education",
        href: `${filterURL}education`,
        icon: "education-icon",
      },
      {
        title: "Salesforce for Nonprofits",
        href: `${filterURL}nonprofit`,
        icon: "nonprofit-icon",
      },
      {
        title: "Service",
        href: `${filterURL}service`,
        icon: "service-icon",
      },
      {
        title: "Strategy & Planning",
        href: `${filterURL}strategy-%2526-planning`,
        icon: "stratplan-icon",
      },
      {
        title: "Tableau",
        href: `${filterURL}tableau`,
        icon: "tableau-icon",
      },
    ];
    return (
      <ProductGrid
        title="Discover Expert Coaching Topics by Product"
        eccType={eccType}
        childData={data}
      />
    );
  };

  const RenderGOProductGrid = () => {
    let filterURL = `/search#tab=Events&f[sitemaplanguage]=${language}&f[successeventproduct]=`;
    let goURL = "&f[successeventeventtype]=webinar";
    const data = [
      {
        title: "Sales",
        href: `${filterURL}sales-cloud${goURL}`,
        icon: "sales-icon",
      },
      {
        title: "Service",
        href: `${filterURL}service-cloud${goURL}`,
        icon: "service-icon",
      },
      {
        title: "Platform",
        href: `${filterURL}salesforce-platform${goURL}`,
        icon: "platform-icon",
      },
      {
        title: "Account Engagement",
        href: `${filterURL}account-engagement${goURL}`,
        icon: "marketing-icon",
        subtitle: "Account Engagement subtitle",
      },
    ];
    return (
      <ProductGrid
        title="Admin Fundamentals Header"
        eccType={eccType}
        childData={data}
      />
    );
  };

  const RenderOnDemandEvents = () => {
    /* const demoData = [
      {
        id: "7013y000002hjnPAAQ",
        title: "Getting Started: Health Cloud for Payers and Providers",
        product: ["health-cloud"],
        status: "Event Updated",
        startDateTime: "2024-03-06T03:00:00.000Z",
        endDateTime: "2024-03-06T04:00:00.000Z",
        region: "APAC",
        eventLanguage: "en",
        category: null,
        eventType: ["expert-coaching-session", "video"],
        eventTimeZone: "India Time (Asia/Kolkata)",
        recordingUrl: null,
        launchUrl: "https://salesforce.zoom.us/s/89224673697",
        externalDetailUrl: null,
        webinarType: "Zoom",
        registrationUrl:
          "https://cloud.mail.salesforce.com/event-registration-form/?zoomid=89224673697",
        overview:
          "Salesforce Health Cloud for Payers and Providers gives healthcare organizations the tools to put their patients at the heart of every decision. In this session, a Salesforce expert will walk you through the features and data model of Health Cloud that apply to common Payer and Provider use cases. The expert also shares best practices, and gives you the tools to plan the configuration or extension of your Health Cloud implementation.\\n\\nPremier and Signature Success Plan customers, request an individual follow-up after the session.",
        agenda: null,
        sessionLength: 60,
        featuredRanking: null,
        isFullFlag: false,
        recordingID: null,
        recordingType: null,
        recurringFlag: true,
        eventGroupType: "Child",
        parentId: "7013y000002EMadAAG",
        eventTier: "premier",
        lastModified: "2024-02-15T01:37:10.000Z",
        topic: "User Training & Development",
        levelOfExpertise: "Beginner",
        role: "Admin",
        language: "English",
      },
      {
        id: "7013y000001zsCFAAY",
        title: "Getting Started: Journey Builder: Journey Strategy",
        product: ["marketing-cloud"],
        status: "Event Updated",
        startDateTime: "2024-03-06T10:00:00.000Z",
        endDateTime: "2024-03-06T11:30:00.000Z",
        region: "EMEA",
        eventLanguage: "en",
        category: null,
        eventType: ["expert-coaching-session", "video"],
        eventTimeZone: "Greenwich Mean Time (GMT)",
        recordingUrl: null,
        launchUrl: "https://salesforce.zoom.us/s/89502294799",
        externalDetailUrl: null,
        webinarType: "Zoom",
        registrationUrl:
          "https://cloud.mail.salesforce.com/event-registration-form/?zoomid=89502294799",
        overview:
          "Approaching your first journey can be intimidating. This webinar empowers marketers with the knowledge to begin with a journey mindset.\\n\\n- Gain a basic understanding of what is needed to start a customer journey.\\n- Learn how to develop a journey practice that can be used to create many journeys in the future, and across your customer life cycle.\\n\\nWe will cover Goal Setting, Audience Definition, Data Audit, Content & Channels Brainstorm, and Journey Mapping.",
        agenda: null,
        sessionLength: 90,
        featuredRanking: null,
        isFullFlag: false,
        recordingID: null,
        recordingType: null,
        recurringFlag: true,
        eventGroupType: "Child",
        parentId: "7013y000000ZbYgAAK",
        eventTier: "signature",
        lastModified: "2024-02-29T10:36:08.000Z",
        topic: "User Training & Development",
        levelOfExpertise: "Beginner",
        role: "Admin",
        language: "English",
      },
      {
        id: "7013y000002hjpZAAQ",
        title:
          "Getting Started: Platform: Install and Connect MuleSoft Composer",
        product: ["mulesoft"],
        status: "Event Updated",
        startDateTime: "2024-03-06T10:00:00.000Z",
        endDateTime: "2024-03-06T11:00:00.000Z",
        region: "EMEA",
        eventLanguage: "en",
        category: null,
        eventType: ["expert-coaching-session", "video"],
        eventTimeZone: "Greenwich Mean Time (GMT)",
        recordingUrl: null,
        launchUrl: "https://salesforce.zoom.us/s/86022982919",
        externalDetailUrl: null,
        webinarType: "Zoom",
        registrationUrl:
          "https://cloud.mail.salesforce.com/event-registration-form/?zoomid=86022982919",
        overview:
          "Explore how MuleSoft Composer makes it easy for you to perform integration tasks and synchronize data across different systems. Learn the steps for installing and connecting MuleSoft Composer and how to create and manage the Anypoint Platform org and account. A Salesforce expert will also review flow creation tasks and core feature components, including email failure notifications.\\n\\nPremier and Signature Success Plan customers, request an individual follow-up after the session.",
        agenda: null,
        sessionLength: 60,
        featuredRanking: null,
        isFullFlag: false,
        recordingID: null,
        recordingType: null,
        recurringFlag: false,
        eventGroupType: "Child",
        parentId: "7013y000002KFdjAAG",
        eventTier: "premier",
        lastModified: "2024-02-15T02:09:02.000Z",
        topic: "User Training & Development",
        levelOfExpertise: "Beginner",
        role: "Admin",
        language: "English",
      },
    ]; */
    // example api call - https://prod-ep-api-na-east-1.herokuapp.com/v1/events?includeRecorded=true&type=expert-coaching-session
    const productLines = appConfig.eventType;
    const filter = {
      groupkey: "group-on-demand-events",
      filters: {
        product: productLines,
        type: ["video"],
      },
      countAcross: 3,
      title: "Stream On-Demand Sessions",
      jumplink: "#tab=Events&f[successeventeventtype]=video",
      seeAllTitle: "See All Expert Coaching Videos",
      eccCardType: "eccondemandsessions",
      showDescription: true,
    };

    return (
      <Container className="onDemandSessionsContainer">
        <div className="maskedBg onDemandSessions">
          <div className="mask"></div>
        </div>
        <Row className="onDemandSessionsRow">
          <Col xs={12} lg={12}>
            <EventFilterGroupContainer
              eventList={onDemandEventsList} //{demoData}
              groupKey={filter.groupkey}
              groupConfig={filter}
              key={filter.groupkey}
              mode={"noborder"}
            />
          </Col>
        </Row>
      </Container>
    );
  };

  const RenderTrendingTopics = () => {
    const groupConfig = {
      groupkey: "group-one",
      filters: {
        product: ["expert-coaching"],
        eventLangauge: ["en", "English"],
      },
      title: "Trending Topics",
      jumplink: "#tab=Events&f[successeventeventtype]=expert-coaching-session",
      seeAllTitle: "Expert Coaching Topics",
      showDescription: true,
    };
    return (
      <TrendingTopicsContainer
        eventList={trendingTopicsList}
        groupKey={"trending-topics-group-test"}
        groupConfig={groupConfig}
        key={"trending-topics-group-test"}
      />
    );
  };

  const RenderCoveoEventsList = () => {
    return (
      <div
        className="coveo-events-list insetPage"
        id="coveo-events-list-container"
      >
        <h2 className="groupHeader">{t(coveoListTitle)}</h2>
        <p class="eccDescription">{t(coveoListDescription)}</p>
        <Container className="fullPanel">
          <Row>
            <Col xs={12} lg={4}>
              <div className="colPanel filterBar coveo--filterBar">
                <Accordion
                  activeKey={
                    isExpanded || windowWidth > mobileBreak ? "1" : "0"
                  }
                  className={
                    isExpanded || windowWidth > mobileBreak
                      ? "expanded"
                      : "collapsed"
                  }
                >
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="1"
                    onClick={decoratedOnClick}
                    className="filterBarToggleBtn"
                  >
                    <h4>{t("Filter Results")}</h4>
                  </Accordion.Toggle>

                  <Button
                    variant="secondary"
                    className="shareButton "
                    onClick={showShareModal}
                  >
                    {t("Share this View")} <ShareIcon />
                  </Button>
                  <Button
                    onClick={showShareModal}
                    variant="link"
                    className="shareButtonInline"
                  >
                    {t("Share this View")}
                  </Button>

                  <Accordion.Collapse eventKey="1">
                    <ListGroup>
                      <Facet
                        controller={productFacet}
                        title={appConfig.filters.product.title}
                        configOptions={appConfig.filters.product.options}
                        searchParams={searchParametersState}
                        facetId="productFacet"
                        key="productFacetKey"
                      />
                      {/* <Facet
                        controller={businesslineFacet}
                        title={appConfig.filters.businessline.title}
                        configOptions={appConfig.filters.businessline.options}
                        searchParams={searchParametersState}
                        facetId="businesslineFacet"
                        key="businesslineFacetKey"
                      /> */}
                      <Facet
                        controller={categoryFacet}
                        title={appConfig.filters.category.title}
                        configOptions={appConfig.filters.category.options}
                        searchParams={searchParametersState}
                        facetId="categoryFacet"
                        key="categoryFacetKey"
                      />
                      <Facet
                        controller={subCategoryFacet}
                        title={appConfig.filters.subCategory.title}
                        configOptions={appConfig.filters.subCategory.options}
                        searchParams={searchParametersState}
                        facetId="subCategoryFacet"
                        key="subCategoryFacetKey"
                      />
                      <Facet
                        controller={customerRoleFacet}
                        title={appConfig.filters.targetCustomerRole.title}
                        configOptions={
                          appConfig.filters.targetCustomerRole.options
                        }
                        searchParams={searchParametersState}
                        facetId="targetCustomerRoleFacet"
                        key="targetCustomerRoleFacetKey"
                      />
                      <Facet
                        controller={eventTypeFacet}
                        title={appConfig.filters.eventType.title}
                        configOptions={appConfig.filters.eventType.options}
                        searchParams={searchParametersState}
                        facetId="eventFacet"
                        key="eventFacetKey"
                      />{" "}
                      <Facet
                        controller={deliveryTypeSubTypeFacet}
                        title={appConfig.filters.deliveryTypeSubType.title}
                        configOptions={
                          appConfig.filters.deliveryTypeSubType.options
                        }
                        searchParams={searchParametersState}
                        facetId="deliveryTypeSubTypeFacet"
                        key="eventFacetKey"
                      />{" "}
                      {/* <Facet
                        controller={businesslineFacet}
                        title={t("Facet Title Business Line")}
                        configOptions={[]}
                        stringPrefix="Product Line Title "
                        searchParams={searchParametersState}
                        facetId="businesslineFacet"
                        key="businesslineFacet"
                      /> */}
                      {/* <Facet
                        controller={customerlifecycleFacet}
                        title={t("Facet Title Customer Lifecycle")}
                        configOptions={[]}
                        stringPrefix="Portrait Phase "
                        searchParams={searchParametersState}
                        facetId="customerlifecycleFacet"
                        key="customerlifecycleFacet"
                      /> */}
                      <Facet
                        controller={languageFacet}
                        title={appConfig.filters.language.title}
                        configOptions={appConfig.filters.language.options}
                        searchParams={searchParametersState}
                        facetId="languageFacet"
                        key="languageFacetKey"
                      />
                      <Facet
                        controller={regionFacet}
                        title={appConfig.filters.region.title}
                        configOptions={appConfig.filters.region.options}
                        searchParams={searchParametersState}
                        facetId="regionFacet"
                        key="regionFacetKey"
                      />
                      <Facet
                        controller={deliveryRoleExpertiseFacet}
                        title={appConfig.filters.deliveryRoleExpertise.title}
                        configOptions={
                          appConfig.filters.deliveryRoleExpertise.options
                        }
                        searchParams={searchParametersState}
                        facetId="deliveryRoleExpertiseFacet"
                        key="deliveryRoleExpertiseFacetKey"
                      />
                      <FacetDateRange
                        controller={dateFacet}
                        searchParametersController={
                          searchParametersFacetDateRange
                        }
                        title="Date Range"
                        timezone={timezone}
                        locale={locale}
                        key="dateFacetKey"
                        isMobile={windowWidth <= mobileBreak}
                        searchParams={searchParametersState}
                      />
                      <FacetToggle
                        controller={tierFacet}
                        title="Tier"
                        key="tierFacetKey"
                      />
                    </ListGroup>
                  </Accordion.Collapse>
                </Accordion>
              </div>
            </Col>
            <Col xs={12} lg={8}>
              <div className="colPanel">
                <ECCFilterTabs
                  allController={eccOverviewTab}
                  coachingController={eccCoachingTab}
                  fundamentalsController={eccAdminfundamentalsTab}
                  onTabChange={resetPage}
                />
                <div className="coveo--result-header">
                  <QuerySummary controller={querySummary} />
                  <SearchPills
                    controller={breadcrumbs}
                    searchParametersController={searchParametersBreadcrumbs}
                    timezone={timezone}
                    locale={locale}
                    configFilters={appConfig.filters}
                  />
                </div>
                <ResultList
                  controller={resultList}
                  templateManager={headlessResultTemplateManager}
                  cardStyle={eccType}
                />
                <Pager
                  controller={pager}
                  element="coveo-events-list-container"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  };

  const metaDescription = t("Default Meta Description");

  return (
    <div className="expertCoaching">
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={metaDescription} />
        <meta name="og:description" content={metaDescription} />
      </Helmet>
      {renderSuccessReviews ? (<RenderSuccessReviews />) : (<RenderECCBanner />)}
      <Row></Row>
      {renderOverview && (
        <>
          <div>
            <RenderFastTrackThreeCol />
          </div>
        </>
      )}
      {renderExpertCoaching && (
        <>
          <div>
            {/* <RenderOnDemandEvents /> */}
            <RenderWaysToLearnThreeCol />
            <RenderECProductGrid />
            {/* <RenderTrendingTopics /> */}
          </div>
        </>
      )}
      {renderAdminfundamentals && (
        <>
          <div>
            <RenderGOProductGrid />
          </div>
        </>
      )}
      {!renderStandard && renderOverview && (
        <>
          <div className="bandedSection--white">
            <RenderPersonalizedGuidance />
          </div>
        </>
      )}

      {!renderSuccessReviews && (<RenderCoveoEventsList />) }
    </div>
  );
};

export default ExpertCoaching;
