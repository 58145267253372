import React, { useState, useEffect } from "react";
// import PropTypes from "prop-types";
// import { useFilterLinkConfig } from "../../hooks/";

// import { useTranslation } from "react-i18next";

// import { Link } from "react-router-dom";

import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import "./PromoSection.scss";

const PromoSection = ({ children, promoTypeKey, imageAlignment }) => {
  // const { t } = useTranslation();

  const [imageSource, setImageSource] = useState(undefined);
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    async function getImages() {
      const img = await import(
        `../../assets/images/promo-images/${promoTypeKey}.png`
      )
        .then((image) => image.default)
        .catch((error) => {
          console.debug("error", error);
        });

      const img2x = await import(
        `../../assets/images/promo-images/${promoTypeKey}@2x.png`
      )
        .then((image) => image.default)
        .catch((error) => {
          console.debug("error", error);
        });

      // If the image doesnt exist, fail gracefully
      if (!img || !img2x) return;
      setImageSource({
        default: img,
        retina: img2x,
      });
    }

    if (promoTypeKey) {
      getImages();
    }
  }, [promoTypeKey]);

  function imageOnLoad() {
    setImageLoaded(true);
  }

  const imgOrder = imageAlignment ? imageAlignment : "first";

  return (
    <Container className={`promoWrapper ${promoTypeKey}`}>
      <Row className={promoTypeKey === "expert-coaching" || promoTypeKey === "personalized-guidance" ? "align-items-center-horiz" : ""}>
        <Col
          xs={{ span: 12, order: imgOrder }}
          md={{ span: (promoTypeKey === "expert-coaching" ? 5 : promoTypeKey === "personalized-guidance" || promoTypeKey === "success-reviews" ? 4 : 6), order: imgOrder }}
          className="promoImageCol"
        >
          {imageSource && (
            <img
              className={`image ${imageLoaded && "image-loaded"}`}
              src={imageSource.default}
              srcSet={`${imageSource.retina} 2x`}
              onLoad={imageOnLoad}
              alt={promoTypeKey}
            />
          )}
        </Col>
        <Col 
          xs={{ span: 12 }} 
          md={{ span: (promoTypeKey === "expert-coaching" || promoTypeKey === "personalizedguidance" || promoTypeKey === "success-reviews" ? 7 : 6) }} 
          className="promoContentCol"
        >
          {children}
        </Col>
      </Row>
    </Container>
  );
};

/*PromoSection.propTypes = {
  eventList: PropTypes.array,
};*/

export default PromoSection;
