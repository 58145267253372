const getEnv = (envString) => {
  return window.SERVER_DATA && window.SERVER_DATA[`HEROKU_${envString}`]
    ? window.SERVER_DATA[`HEROKU_${envString}`]
    : process.env[`REACT_APP_${envString}`];
};

export const API_BASE_DOMAIN = getEnv("API_BASE_DOMAIN");
export const CLIENT_DOMAIN = getEnv("CLIENT_DOMAIN");
export const GOOGLE_RECAPTCHA_SITEKEY = getEnv("GOOGLE_RECAPTCHA_SITEKEY");
export const TBID_CLIENT_KEY = getEnv("TBID_CLIENT_KEY");
export const TBID_CLIENT_SECRET = getEnv("TBID_CLIENT_SECRET");
export const TBID_DOMAIN = getEnv("TBID_DOMAIN");
export const IS_MOCK_TBID_USER = getEnv("IS_MOCK_TBID_USER") === "true";
export const IS_LOCAL = getEnv("IS_LOCAL") === "true";
export const COVEO_API_TOKEN = getEnv("COVEO_API_TOKEN");
export const COVEO_UA_SRC = getEnv("COVEO_UA_SRC");
export const COVEO_UA_ONLY_API_KEY = getEnv("COVEO_UA_ONLY_API_KEY");
export const COVEO_SEARCH_HUB = getEnv("COVEO_SEARCH_HUB");
export const COVEO_SEARCH_PIPELINE = getEnv("COVEO_SEARCH_PIPELINE");
export const COVEO_RECOMMENDATION_HUB = getEnv("COVEO_RECOMMENDATION_HUB");
export const COVEO_RECOMMENDATION_PIPELINE = getEnv("COVEO_RECOMMENDATION_PIPELINE");
export const COVEO_ORG_ID = getEnv("COVEO_ORG_ID");
export const COVEO_PLATFORM_API_KEY = getEnv("COVEO_PLATFORM_API_KEY");
export const GSV_ID = getEnv("GSV_ID");
export const GTM_ID = getEnv("GTM_ID");
export const OTC_DOMAIN = getEnv("OTC_DOMAIN");
export const OTC_SRC = getEnv("OTC_SRC");
export const ROBOTS = getEnv("ROBOTS");
export const SPRIG_ENVIRONMENT_ID = getEnv("SPRIG_ENVIRONMENT_ID");
export const SUCCESS_REVIEW_CASE_URL = getEnv("SUCCESS_REVIEW_CASE_URL");

