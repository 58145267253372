/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";

import "./ECCFilterTabs.scss";

export const ECCFilterTabs = (props) => {
  const {
    allController,
    coachingController,
    fundamentalsController,
    onTabChange,
  } = props;
  const [allState, setAllState] = useState(allController.state);
  const [eventsState, setEventsState] = useState(coachingController.state);
  const [portraitsState, setPortraitsState] = useState(fundamentalsController.state);

  const { t } = useTranslation();

  useEffect(() => {
    const allSubscribe = allController.subscribe(() =>
      setAllState(allController.state)
    );
    const eventsSubscribe = coachingController.subscribe(() =>
      setEventsState(coachingController.state)
    );
    const portraitsSubscribe = fundamentalsController.subscribe(() =>
      setPortraitsState(fundamentalsController.state)
    );

    return () => {
      allSubscribe();
      eventsSubscribe();
      portraitsSubscribe();
    };
  }, [
    allController,
    coachingController,
    fundamentalsController,
  ]);

  const setTab = (key) => {
    onTabChange();
    switch (key) {
      case "expert-coaching":
        coachingController.select();
        break;
      case "admin-fundamentals":
        fundamentalsController.select();
        break;
      case "overview":
      default:
        allController.select();
        break;
    }
  };

  const scrollToTab = (tabId) => {
    const tabsElement = document.querySelector(".coveo--filter-tabs");
    const tabElement = document
      .getElementById(`filter-tabs-tab-${tabId}`)
      .getBoundingClientRect();
    tabsElement.scrollTo({
      top: tabElement.top,
      left: tabElement.left,
      behavior: "smooth",
    });
  };

  let tabKey = "overview";

  if (allController.state.isActive) {
    tabKey = "overview";
    setTimeout(() => {
      scrollToTab("overview");
    });
  }
  if (coachingController.state.isActive) {
    tabKey = "expert-coaching";
  }
  if (fundamentalsController.state.isActive) {
    tabKey = "admin-fundamentals";
  }

  return (
    <Tabs
      id="filter-tabs"
      className="coveo--filter-tabs"
      activeKey={tabKey}
      onSelect={(k) => setTab(k)}
    >
      {allController.state.isActive && <Tab eventKey="overview" title={t("Search All Results Tab")} />}
      {coachingController.state.isActive && <Tab eventKey="expert-coaching" title={t("Expert Coaching")} />}
      {fundamentalsController.state.isActive && <Tab eventKey="admin-fundamentals" title={t("Admin Fundamentals Series")} />}
    </Tabs>
  );
};