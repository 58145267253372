/* eslint-disable react-hooks/exhaustive-deps */
import { EventActions, OtherActions, UserActions } from "../../store/actions";

import React, { useState, useEffect } from "react";
import { useEventTranslation, useFilterLinkConfig } from "../../hooks/";

import { useTranslation } from "react-i18next";

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Jumbotron from "react-bootstrap/Jumbotron";

import moment from "moment-timezone";
import Moment from "react-moment";

import { Link, Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";

import EventFilterGroupContainer from "../../containers/EventFilterGroupContainer";

import { HeroBanner } from "../../components/HeroBanner";

import { CSSTransition } from "react-transition-group";

import { Event } from "../../components/Event";

import { ErrorPage } from "../ErrorPage";

import "./EventDetail.scss";

import { ReactComponent as ShareIcon } from "../../assets/images/icon_share.svg";
import { EventRegistration } from "../../components/EventRegistration";

import EventComplete from "../../components/EventComplete";
import Like from "../../components/Like/Like";

import { formatTextContent } from "@/utils/stringHelpers";

import { IS_MOCK_TBID_USER, CLIENT_DOMAIN } from "../../config/environment";
import { initiateLogin } from "@/utils/userHelper";
import {
  sendCoveoViewEvent,
  sendCoveoCustomEvent,
} from "@/utils/analyticsHelper";

const {
  loadEventDetail,
  loadEventFilterGroup,
  loadRecurringEvents,
  loadParentEvent,
  loadParentSessions,
  loadEventDetailUserRegistration,
  setRegistrationEventCancel,
} = EventActions;

const { toggleShareModal } = OtherActions;

const { getUserFavoritesAll } = UserActions;

const EventDetail = ({
  eventId,
  user,
  tb_id,
  favorites,
  tbidUser,
  eventDetail,
  eventDetailRecurring,
  parentEventDetail,
  parentEventSessions,
  language,
  isLoading,
  notFoundId,
  criticalError,
  eventsWithRegistration,
  eventsWithFailedRegistration,
  eventDetailUserRegistration,
  dispatch,
}) => {
  useEffect(() => {
    // if we have another event's details or no event detail in store, request it
    if (
      !eventDetail ||
      (eventDetail.id !== eventId && eventId !== notFoundId)
    ) {
      setHasInit(true);
      setHasRegInit(false);
      setHasRecurringInit(false);
      setRecurringExpanded(false);
      setHasParentInit(false);
      setHasParentSessionsInit(false);
      setHasBucketParent(false);
      setIsBucketParent(false);
      setRegistrationStatus(false);
      let eventObject = { id: eventId };
      dispatch(loadEventDetail(eventObject));
    } else if (eventDetail && eventDetail.id === eventId) {
      setHasInit(true);
    } else if (notFoundId === eventId) {
      setNotFound(true);
    }

    // determine same day status, format appropriately
    let sameDayFlag = moment(eventDetail.startDateTime).isSame(
      moment(eventDetail.endDateTime),
      "day"
    );

    setSameDay(sameDayFlag);

    // determine event registration status
    let regClosed =
      eventDetail.status === "Event Canceled" ||
      eventDetail.isFullFlag === true ||
      moment(eventDetail.endDateTime).isBefore();

    setRegClosed(regClosed);

    if (moment(eventDetail.endDateTime).isBefore()) {
      setRegClosedStatus("past");
    } else if (eventDetail.status === "Event Canceled") {
      setRegClosedStatus("cancelled");
    } else if (eventDetail.isFullFlag) {
      setRegClosedStatus("full");
    } else {
      setRegClosedStatus("generic");
    }

    setRegistrationStatus(eventDetailUserRegistration);

    // init favorites
    if (tbidUser && !hasFavoritesInit) {
      dispatch(getUserFavoritesAll({ tb_id }));
      setHasFavoritesInit(true);
    }
  }, [
    eventId,
    tbidUser,
    eventDetail,
    notFoundId,
    eventDetailUserRegistration,
    dispatch,
  ]);

  const [hasFavoritesInit, setHasFavoritesInit] = useState(false);

  const { t } = useTranslation();
  const filterConfigParams = useFilterLinkConfig();
  const favoritesIds = favorites ? favorites : [];

  const checkFavorite = (eventId) => {
    return favoritesIds.includes(eventId);
  };
  const [hasInit, setHasInit] = useState(false);
  const [hasAnalyticsInit, setHasAnalyticsInit] = useState(false);
  const [hasRegInit, setHasRegInit] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [sameDay, setSameDay] = useState(false);
  const [sameDayParent, setSameDayParent] = useState(false);

  const [hasGroupInit, setHasGroupInit] = useState(false);
  const [groupConfig, setGroupConfig] = useState(false);

  const [hasRecurring, setHasRecurring] = useState(false);
  const [hasRecurringInit, setHasRecurringInit] = useState(false);

  const [hasParentInit, setHasParentInit] = useState(false);
  const [hasParentSessionsInit, setHasParentSessionsInit] = useState(false);
  const [hasBucketParent, setHasBucketParent] = useState(false);
  const [isBucketParent, setIsBucketParent] = useState(false);

  const [regClosed, setRegClosed] = useState(false);
  const [regClosedStatus, setRegClosedStatus] = useState("");
  const [registrationStatus, setRegistrationStatus] = useState(false);

  const [hasRecurringExpanded, setRecurringExpanded] = useState(false);

  const showShareModal = () => {
    // log share event to coveo
    sendCoveoCustomEvent("userEventShare", "launchShare", eventDetail);
    let modalPayload = { show: true };
    dispatch(toggleShareModal(modalPayload));
  };

  let eventTypeKey = eventDetail.eventType ? eventDetail.eventType[0] : null;
  let eventProductKey = eventDetail.product ? eventDetail.product[0] : null;

  let eventTypeTranslated = useEventTranslation("eventType", eventTypeKey);

  let agendaFormatted = formatTextContent(eventDetail.agenda);
  let overviewFormatted = formatTextContent(eventDetail.overview);
  let overviewMeta = eventDetail.overview
    ? eventDetail.overview.replace(/["']/g, "")
    : null;

  let parentOverviewFormatted = formatTextContent(parentEventDetail.overview);

  // uncomment if need use
  let addressFormatted = formatTextContent(
    eventDetail.address && eventDetail.address.street
      ? eventDetail.address.street
      : "",
    false
  );

  let eventPrimaryProduct = eventDetail.product ? eventDetail.product[0] : null;
  //let eventPrimaryTitle = useEventTranslation("product", eventPrimaryProduct);
  let filterGroupKey = "group-detail";

  const isRegistrationAvailable =
    !regClosed &&
    (eventDetail.externalDetailUrl || eventDetail.registrationUrl) &&
    !isBucketParent;

  // analytics init
  if (!isLoading && !hasAnalyticsInit && hasInit) {
    setTimeout(() => {
      sendCoveoViewEvent();
    });
    sendCoveoCustomEvent("userEventView", "viewEvent", eventDetail);
    setHasAnalyticsInit(true);
  }

  // filter group init
  if (!isLoading && !hasGroupInit && hasInit && eventPrimaryProduct) {
    let filterGroupConfig = {
      groupkey: filterGroupKey,
      filters: { product: [eventPrimaryProduct] },
      title: "Related Events",
    };
    setGroupConfig(filterGroupConfig);

    setHasGroupInit(true);
    dispatch(loadEventFilterGroup(filterGroupConfig));
  }

  if (!isLoading && hasInit && tbidUser && !hasRegInit) {
    dispatch(
      loadEventDetailUserRegistration({
        tbidId: user.tb_id,
        eventId: eventDetail.id,
      })
    );
    setHasRegInit(true);
  }

  // recurring events init
  if (!isLoading && hasInit && !hasRecurringInit) {
    // get actual parent event id
    setHasRecurringInit(true);
    if (eventDetail.recurringFlag === true && eventDetail.parentId) {
      let parentEventFilter = {
        parentEventId: eventDetail.parentId,
      };
      dispatch(loadRecurringEvents(parentEventFilter));
      setHasRecurring(true);
    } else {
      setHasRecurring(false);
    }
  }

  const showRecurringExpanded = () => {
    setRecurringExpanded(true);
  };

  const recurringCutoff = 3;
  // exclude this event from list
  const recurringEventList = eventDetailRecurring.filter(
    (event) => event.id !== eventDetail.id
  );

  const visibleRecurringEventList = !hasRecurringExpanded
    ? recurringEventList.slice(0, recurringCutoff)
    : recurringEventList;

  const showRecurring = recurringEventList.length > 0;

  const RenderRecurringEvents = () => {
    return (
      <div className="recurringEventWrapper">
        <h3>Additional Times</h3>
        {visibleRecurringEventList.map((event, index) => (
          <Event
            event={event}
            tb_id={tb_id}
            isFavorite={() => checkFavorite(event.id)}
            key={event.id}
            minimized={true}
            {...event}
          />
        ))}
        {recurringEventList.length > recurringCutoff && !hasRecurringExpanded && (
          <Button
            onClick={(e) => {
              e.preventDefault();
              showRecurringExpanded();
            }}
            variant="link"
            className="inlineLink"
          >
            {t("View All Times")}
          </Button>
        )}
      </div>
    );
  };

  // event bucket parent check
  if (!isLoading && hasInit && !hasParentInit) {
    // get actual parent event id
    setHasParentInit(true);
    if (eventDetail.eventGroupType === "Bucket Parent") {
      let parentEventFilter = {
        parentEventId: eventDetail.id,
      };
      dispatch(loadParentEvent(parentEventFilter));
      setIsBucketParent(true);
    }
    // loading parent event for bucketparent check
    else if (eventDetail.recurringFlag === false && eventDetail.parentId) {
      let parentEventFilter = {
        parentEventId: eventDetail.parentId,
      };
      dispatch(loadParentEvent(parentEventFilter));
    } else {
      // not loading parent event
    }
  } else if (
    !isLoading &&
    hasInit &&
    hasParentInit &&
    parentEventDetail.eventGroupType &&
    !hasParentSessionsInit
  ) {
    // parent event detail loaded
    setHasParentSessionsInit(true);
    // console.log(parentEventDetail.eventGroupType);
    if (parentEventDetail.eventGroupType === "Bucket Parent") {
      setHasBucketParent(true);
      // determine same day status, format appropriately
      let sameDayParentFlag = moment(parentEventDetail.startDateTime).isSame(
        moment(parentEventDetail.endDateTime),
        "day"
      );
      setSameDayParent(sameDayParentFlag);

      let parentEventFilter = {
        parentEventId: parentEventDetail.id,
      };
      dispatch(loadParentSessions(parentEventFilter));
      //console.log("loading parent sessions");
    } else {
      setHasBucketParent(false);
    }
  }

  const RenderParentEventDetails = () => {
    return (
      <Row>
        <Col xs={12}>
          <div className="bucketParentHeader">
            <h3 className="groupHeader">{parentEventDetail.title} Sessions</h3>
            <span className="parentDateSpan">
              <Moment date={parentEventDetail.startDateTime} format="LL" />

              {sameDayParent ? (
                ""
              ) : (
                <>
                  {" - "}
                  <Moment date={parentEventDetail.endDateTime} format="LL" />
                </>
              )}
            </span>
            <p
              dangerouslySetInnerHTML={{
                __html: parentOverviewFormatted,
              }}
            ></p>
            <Link
              to={{
                pathname: "/events/" + parentEventDetail.id,
              }}
              className="btn btn-primary"
            >
              {t("View Detail")}
            </Link>
          </div>
        </Col>
      </Row>
    );
  };

  const headerDateFormat = isBucketParent ? "LL" : "LLLL";

  let parentSessionsObj = {};

  if (parentEventSessions.length > 0) {
    // fold parent sessions into startTime based layout
    let currDayTime = "01 Jan 2000";
    parentEventSessions.forEach((eventItem) => {
      // if this event falls on a new day, change the parent index
      if (!moment(currDayTime).isSame(moment(eventItem.startDateTime), "day")) {
        currDayTime = eventItem.startDateTime;
        parentSessionsObj[currDayTime] = {};
      }
      if (
        !parentSessionsObj[currDayTime].hasOwnProperty(eventItem.startDateTime)
      ) {
        parentSessionsObj[currDayTime][eventItem.startDateTime] = [];
      }
      parentSessionsObj[currDayTime][eventItem.startDateTime].push(eventItem);
    });
    //console.log(parentSessionsObj);
  }

  const RenderParentEventSessions = () => {
    return (
      <Row>
        {Object.keys(parentSessionsObj).map((startDayTime, index) => (
          <>
            <Col xs={12}>
              <div className="parentDateSpan listHeader">
                <Moment date={startDayTime} format="LL" />
              </div>
            </Col>

            {Object.keys(parentSessionsObj[startDayTime]).map(
              (startTime, index) => (
                <>
                  <Col xs={12}>
                    <div className="sessionTimeHeader">
                      <Moment date={startTime} format="LT" />
                    </div>
                  </Col>
                  {parentSessionsObj[startDayTime][startTime].map(
                    (event, indexY) => (
                      <Col
                        xs={12}
                        md={6}
                        lg={4}
                        xl={4}
                        key={"event-index-" + index}
                      >
                        <Event
                          event={event}
                          key={event.id}
                          tb_user_plan={userStatus}
                          {...event}
                          tb_id={tb_id}
                          isFavorite={() => checkFavorite(event.id)}
                        />
                      </Col>
                    )
                  )}
                </>
              )
            )}
          </>
        ))}
      </Row>
    );
  };

  const pageTitle = eventDetail.title + " - " + t("Customer Success Events");

  // error handling
  if (criticalError) {
    return <ErrorPage errorType="500" />;
  }

  if (!isLoading && notFound) {
    return <ErrorPage errorType="404" />;
  }

  if (eventDetail.status === "Event Canceled") {
    return <Redirect to="/" />;
  }

  const cancelEventRegistration = () => {
    // log registration cancel event to coveo
    sendCoveoCustomEvent(
      "userEventRegistration",
      "cancelRegistration",
      eventDetail
    );

    dispatch(
      setRegistrationEventCancel({
        tbidId: tb_id,
        eventId: eventDetail.id,
      })
    );
  };

  const checkCompleteRegistration = () => {
    return Boolean(
      eventsWithRegistration.find((eventId) => eventId === eventDetail.id)
    );
  };

  const checkFailedRegistration = () => {
    return Boolean(
      eventsWithFailedRegistration.find((eventId) => eventId === eventDetail.id)
    );
  };

  let eventPremierStatus = "Premier";
  let userStatus = "guest";

  if (user && user.success_plan) {
    eventPremierStatus =
      user.success_plan === "signature" ? "Signature" : "Premier";
    userStatus = user.success_plan;
  }

  const userIsPremier = userStatus === "signature" || userStatus === "premier";

  const renderPremier =
    eventDetail.eventTier &&
    (eventDetail.eventTier === "signature" ||
      eventDetail.eventTier === "premier");

  const RenderPremierEventBanner = () => {
    return (
      <div className="eventPremierBanner">
        <Container>
          <Row>
            <Col
              xs={{ span: 12, offset: 0 }}
              sm={{ span: 10, offset: 1 }}
              lg={{ span: 8, offset: 2 }}
              className="bannerMessage"
            >
              <h3 className="groupHeader">
                {t("Premier event detail Top Banner Text", {
                  premier_status: eventPremierStatus,
                })}
              </h3>
            </Col>
          </Row>
        </Container>
      </div>
    );
  };

  const trailblazerLogin = async () => {
    const eventLink = "/events/" + eventDetail.id;
    initiateLogin(IS_MOCK_TBID_USER, eventLink);
  };

  // calculate launch window
  let currentTz = Moment.globalTimezone;
  const launchWindowStart = moment(eventDetail.startDateTime)
    .tz(currentTz)
    .subtract(1, "hours");

  const inLaunchWindow = moment()
    .tz(currentTz)
    .isBetween(launchWindowStart, moment(eventDetail.endDateTime));

  const cardStartDateFormat = isBucketParent
    ? sameDay
      ? "MMMM D, YYYY"
      : "MMMM D"
    : "ddd, h:mm A z";

  // set up flags for conditional display
  const isRegistered =
    registrationStatus && registrationStatus.registration_status;

  const isRegisteredFullEvent = isRegistered && regClosedStatus === "full";

  const absoluteEventLink = `https://${CLIENT_DOMAIN}/events/${eventDetail.id}`;

  // set up structured data
  const structuredData =
    eventTypeKey !== "video"
      ? {
          "@context": "https://schema.org",
          "@type": "Event",
          name: eventDetail.title,
          description: overviewMeta,
          startDate: eventDetail.startDateTime,
          location: {
            "@type": "VirtualLocation",
            url: absoluteEventLink,
          },
          endDate: eventDetail.endDateTime,
          eventStatus: "https://schema.org/EventScheduled",
          eventAttendanceMode: "https://schema.org/OnlineEventAttendanceMode",
          organizer: {
            "@type": "Organization",
            name: "Salesforce",
            url: "https://cs.salesforce.com",
          },
        }
      : {
          "@context": "https://schema.org",
          "@type": "VideoObject",
          name: eventDetail.title,
          description: overviewMeta,
          uploadDate: eventDetail.lastModified,
          duration: eventDetail.sessionLength
            ? moment.duration({ minutes: eventDetail.sessionLength }).toJSON()
            : "",
          thumbnailUrl: eventDetail.recordingID
            ? `https://play.vidyard.com/${eventDetail.recordingID}.jpg`
            : "",
          embedUrl: eventDetail.recordingID
            ? `https://play.vidyard.com/${eventDetail.recordingID}`
            : "",
        };

  return (
    <CSSTransition appear in timeout={500} classNames="page" unmountOnExit>
      <div>
        <div className="eventDetailWrapper">
          {!isLoading && hasInit && !notFound && (
            <div>
              <Helmet>
                <title>{pageTitle}</title>
                <meta name="description" content={overviewMeta} />
                <meta name="og:description" content={overviewMeta} />
                <script
                  type="application/ld+json"
                  key="structured-data"
                  id="structured-data"
                >
                  {JSON.stringify(structuredData)}
                </script>
              </Helmet>
              {renderPremier && !userIsPremier && <RenderPremierEventBanner />}
              <HeroBanner
                eventProductKey={eventProductKey}
                eventDetail={eventDetail}
                eventTypeKey={isBucketParent ? "bucket-parent" : eventTypeKey}
                user={user}
              >
                <Row className="detailHeaderRow">
                  <Col xs={10} lg={9}>
                    <Jumbotron>
                      {hasBucketParent && parentEventDetail && !isBucketParent && (
                        <Link
                          to={{
                            pathname: "/events/" + parentEventDetail.id,
                          }}
                          className="detailBreadcrumb"
                        >
                          <span>{parentEventDetail.title}</span>
                        </Link>
                      )}

                      <hgroup className="hero-header-content">
                        <span className="detailEyebrow">
                          {eventTypeTranslated &&
                            !isBucketParent &&
                            eventTypeTranslated}
                          {renderPremier && (
                            <>
                              {" | "}

                              {t("Premier event detail Event Header Sub", {
                                premier_status: eventPremierStatus,
                              })}
                            </>
                          )}
                        </span>
                        <h1>{eventDetail.title}</h1>
                        <div className="detailSubhead">
                          <Moment
                            date={eventDetail.startDateTime}
                            format={headerDateFormat}
                          />
                          {sameDay && !isBucketParent && (
                            <>
                              {" - "}
                              <Moment
                                date={eventDetail.endDateTime}
                                format="LT"
                              />
                            </>
                          )}
                          {!sameDay && (
                            <>
                              {" - "}
                              <Moment
                                date={eventDetail.endDateTime}
                                format={headerDateFormat}
                              />
                            </>
                          )}{" "}
                          {!isBucketParent && (
                            <Moment
                              date={eventDetail.startDateTime}
                              format="z"
                            />
                          )}
                        </div>
                        <div className="detailSubhead">
                          <Like
                            id={eventDetail.id}
                            tb_id={tb_id}
                            t={t}
                            detail={true}
                            isFavorite={() => checkFavorite(eventDetail.id)}
                            event={eventDetail}
                          />
                        </div>
                        {isBucketParent && overviewFormatted && (
                          <div
                            className="detailSubhead headerOverview"
                            dangerouslySetInnerHTML={{
                              __html: overviewFormatted,
                            }}
                          ></div>
                        )}
                      </hgroup>
                    </Jumbotron>
                  </Col>
                </Row>
              </HeroBanner>

              <Container>
                <div className="insetPage">
                  <Row>
                    <Col
                      xs={12}
                      sm={12}
                      md={eventTypeKey !== "video" ? 5 : 12}
                      lg={eventTypeKey !== "video" ? 6 : 12}
                    >
                      <div className="detailSection">
                        <h2>{t("Overview")}</h2>
                        <p
                          className="richText"
                          dangerouslySetInnerHTML={{
                            __html: overviewFormatted,
                          }}
                        ></p>
                      </div>
                      {addressFormatted && (
                        <div className="detailSection">
                          <h2>{t("Location")}</h2>
                          <p
                            className="richText"
                            dangerouslySetInnerHTML={{
                              __html: addressFormatted,
                            }}
                          ></p>
                        </div>
                      )}
                      {!isBucketParent && agendaFormatted && (
                        <div className="detailSection">
                          <h3>{t("Agenda")}</h3>
                          <p
                            className="richText"
                            dangerouslySetInnerHTML={{
                              __html: agendaFormatted,
                            }}
                          ></p>
                        </div>
                      )}
                      <div className="detailSection">
                        <Button
                          variant="secondary"
                          className="shareButton"
                          onClick={showShareModal}
                        >
                          {t("Share this Event")} <ShareIcon />
                        </Button>
                      </div>
                    </Col>
                    {eventTypeKey !== "video" && (
                      <Col
                        xs={{ span: 12, order: "first" }}
                        sm={{ span: 12, order: "first" }}
                        md={{ span: 6, offset: 1, order: "last" }}
                        lg={{ span: 5, offset: 1, order: "last" }}
                      >
                        <div>
                          <Card className="eventDetailCard">
                            {(!renderPremier ||
                              (renderPremier && userIsPremier) ||
                              isRegistered) && (
                              <Card.Header
                                className={
                                  renderPremier &&
                                  `premierCardDetail ${
                                    isRegistered ? "premier" : userStatus
                                  }`
                                }
                              >
                                <div className="dateCallout">
                                  <span className="dateCallout--month">
                                    <Moment
                                      date={eventDetail.startDateTime}
                                      format="MMM"
                                    />
                                  </span>
                                  <span className="dateCallout--day">
                                    <Moment
                                      date={eventDetail.startDateTime}
                                      format="D"
                                    />
                                    {isBucketParent && !sameDay && (
                                      <span className="dateSup">+</span>
                                    )}
                                  </span>
                                </div>
                                <div className="eventHeaderSub">
                                  {renderPremier && (
                                    <span className="eventHeaderSub--item premierHeaderSub">
                                      {t(
                                        "Premier event detail Event Header Sub",
                                        {
                                          premier_status: eventPremierStatus,
                                        }
                                      )}
                                    </span>
                                  )}
                                  <span className="eventHeaderSub--item">
                                    {isBucketParent &&
                                      (sameDay ? (
                                        <Moment
                                          date={eventDetail.startDateTime}
                                          format={cardStartDateFormat}
                                        />
                                      ) : (
                                        <>
                                          <Moment
                                            date={eventDetail.startDateTime}
                                            format={cardStartDateFormat}
                                          />
                                          {" - "}
                                          <Moment
                                            date={eventDetail.endDateTime}
                                            format={
                                              cardStartDateFormat + ", YYYY"
                                            }
                                          />
                                        </>
                                      ))}
                                    {!isBucketParent && (
                                      <Moment
                                        date={eventDetail.startDateTime}
                                        format="dddd @ h:mm A z"
                                      />
                                    )}
                                  </span>
                                </div>
                              </Card.Header>
                            )}
                            {renderPremier && !userIsPremier && !isRegistered && (
                              <>
                                <Card.Header
                                  className={`premierCardDetail ${userStatus}`}
                                >
                                  <h3 className="groupHeader">
                                    {t("Premier event detail Top Banner Text", {
                                      premier_status: eventPremierStatus,
                                    })}
                                  </h3>
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html: t(
                                        "Premier event detail Card Description"
                                      ),
                                    }}
                                  ></p>
                                </Card.Header>
                                {userStatus === "guest" && (
                                  <Card.Body
                                    className={`premierCardDetailContent ${userStatus}`}
                                  >
                                    {" "}
                                    <>
                                      <p>
                                        {t(
                                          "Premier event detail Card Sign Up Description"
                                        )}
                                      </p>
                                      <div
                                        className="signin-button"
                                        onClick={trailblazerLogin}
                                      >
                                        {t("Login with Trailblazer")}
                                      </div>
                                    </>
                                  </Card.Body>
                                )}
                              </>
                            )}
                            {((renderPremier && userIsPremier) ||
                              !renderPremier ||
                              isRegistered) && (
                              <Card.Body>
                                {(isRegistrationAvailable ||
                                  isRegisteredFullEvent) && (
                                  <div className="registration-form-container">
                                    {isRegistered ? (
                                      <EventComplete
                                        share={{
                                          title: t("Share this Event"),
                                          action: showShareModal,
                                        }}
                                        filterConfigParams={filterConfigParams}
                                        registrationStatus={registrationStatus}
                                        eventDetail={eventDetail}
                                        tbidUser={tbidUser}
                                        premierPriorReg={
                                          isRegistered &&
                                          !userIsPremier &&
                                          renderPremier
                                        }
                                        registeredFullEvent={
                                          isRegisteredFullEvent
                                        }
                                        onCancel={cancelEventRegistration}
                                        inLaunchWindow={inLaunchWindow}
                                        t={t}
                                      />
                                    ) : (
                                      <EventRegistration
                                        share={{
                                          title: t("Share this Event"),
                                          action: showShareModal,
                                        }}
                                        user={user}
                                        tbidUser={tbidUser}
                                        isRegistrationComplete={checkCompleteRegistration()}
                                        isRegistrationFailed={checkFailedRegistration()}
                                        dispatch={dispatch}
                                        eventDetail={eventDetail}
                                        trailblazerLogin={trailblazerLogin}
                                      />
                                    )}
                                  </div>
                                )}
                                {regClosed && !isRegisteredFullEvent && (
                                  <div className="eventStatusInline">
                                    <span className="statusHeader">
                                      {regClosedStatus === "cancelled" &&
                                        t(
                                          "This event has been cancelled by the organizer."
                                        )}
                                      {regClosedStatus === "full" &&
                                        t("This event is currently full.")}
                                      {regClosedStatus === "past" &&
                                        t("This event has already occured.")}
                                      {regClosedStatus === "generic" &&
                                        t(
                                          "Registration is not available for this event."
                                        )}
                                    </span>
                                    <p className="statusText">
                                      {t(
                                        "Use the Events List to explore other events and times."
                                      )}
                                    </p>
                                    <Link
                                      to={{
                                        pathname: "/events",
                                      }}
                                      className="btn btn-primary"
                                    >
                                      {t("Find Events")}
                                    </Link>
                                  </div>
                                )}
                                {isBucketParent && (
                                  <div className="eventStatusInline">
                                    <span className="statusHeader">
                                      {eventTypeTranslated}
                                    </span>
                                    <p className="statusText">
                                      {t(
                                        "See event sessions below to register for this event."
                                      )}
                                    </p>
                                  </div>
                                )}
                              </Card.Body>
                            )}
                          </Card>

                          {hasRecurring && showRecurring && (
                            <RenderRecurringEvents />
                          )}
                        </div>
                      </Col>
                    )}
                  </Row>
                </div>
                {hasBucketParent && eventDetail.id !== parentEventDetail.id && (
                  <RenderParentEventDetails />
                )}
                {hasBucketParent && parentSessionsObj && (
                  <RenderParentEventSessions />
                )}

                {hasGroupInit && !hasBucketParent && (
                  <EventFilterGroupContainer
                    groupKey={filterGroupKey}
                    groupConfig={groupConfig}
                    eventExclude={eventDetail.id}
                    key={filterGroupKey}
                  />
                )}
              </Container>
            </div>
          )}
        </div>
      </div>
    </CSSTransition>
  );
};

export default EventDetail;
